var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('loading'):(_vm.isError)?_c('error'):(
    !_vm.subPermission.show || !_vm.subPermission.update || !_vm.subPermission.store
  )?_c('content-not-view'):_c('div',[_c('validation-observer',{ref:"complaintForm"},[_c('b-form',[_c('b-card',{staticClass:"sticky-header-control"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"md":"6"}},[_c('h3',{staticClass:"text-primary m-0 p-0"},[_vm._v(" "+_vm._s(_vm.is_investigation ? _vm.$t("g.InvestigationDetails") : _vm.$t("g.complaint"))+" | ")]),_c('b-breadcrumb',{attrs:{"items":_vm.breadCrumbItems}})],1),(_vm.show)?_c('b-col',{staticClass:"justify-content-end d-flex",attrs:{"md":"6"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(_vm._s(_vm.$t("g.submit")))])],1):_vm._e()],1)],1),_c('b-card',{attrs:{"title":_vm.$t('g.ComplainantDetails')}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.theNameOfTheComplainant')}},[_c('validation-provider',{attrs:{"name":"the name of the complainant","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.isEdit,"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.entertheNameOfTheComplainantHere')},model:{value:(_vm.form_data.complainant_name),callback:function ($$v) {_vm.$set(_vm.form_data, "complainant_name", $$v)},expression:"form_data.complainant_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.position')}},[_c('validation-provider',{attrs:{"name":"position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.isEdit,"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheComplainantJobHere')},model:{value:(_vm.form_data.complainant_job),callback:function ($$v) {_vm.$set(_vm.form_data, "complainant_job", $$v)},expression:"form_data.complainant_job"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.email')}},[_c('validation-provider',{attrs:{"name":"Complainant email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.isEdit,"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheComplainantEmailHere')},model:{value:(_vm.form_data.complainant_email),callback:function ($$v) {_vm.$set(_vm.form_data, "complainant_email", $$v)},expression:"form_data.complainant_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.companyName')}},[_c('validation-provider',{attrs:{"name":"company name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.isEdit,"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheCompanyNameHere')},model:{value:(_vm.form_data.complainant_company),callback:function ($$v) {_vm.$set(_vm.form_data, "complainant_company", $$v)},expression:"form_data.complainant_company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.companyAddress')}},[_c('validation-provider',{attrs:{"name":"company address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.isEdit,"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheCompanyAddressHere')},model:{value:(_vm.form_data.complainant_address),callback:function ($$v) {_vm.$set(_vm.form_data, "complainant_address", $$v)},expression:"form_data.complainant_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.phoneNumber')}},[_c('validation-provider',{attrs:{"name":"phone number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.isEdit,"state":errors.length > 0 ? false : null,"type":"number","placeholder":_vm.$t('g.placeholder.enterThePhoneNumberHere')},model:{value:(_vm.form_data.complainant_phone),callback:function ($$v) {_vm.$set(_vm.form_data, "complainant_phone", $$v)},expression:"form_data.complainant_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1)],1)],1),_c('b-card',{attrs:{"title":_vm.$t('g.ComplaintDetails')}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.theLocationOfTheComplaintMatter')}},[_c('validation-provider',{attrs:{"name":"location of the complaint matter","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.isEdit,"state":errors.length > 0 ? false : null,"placeholder":_vm.$t(
                      'g.placeholder.enterTheLocationOfTheComplaintMatterHere'
                    )},model:{value:(_vm.form_data.place),callback:function ($$v) {_vm.$set(_vm.form_data, "place", $$v)},expression:"form_data.place"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.complaintDate')}},[_c('validation-provider',{attrs:{"name":"complaint date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"disabled":_vm.isEdit,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.date),callback:function ($$v) {_vm.$set(_vm.form_data, "date", $$v)},expression:"form_data.date"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.complaintSummary')}},[_c('validation-provider',{attrs:{"name":"complaint summary","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":_vm.$t('g.placeholder.enterThecomplaintSummaryHere'),"disabled":_vm.isEdit,"rows":"3","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.description),callback:function ($$v) {_vm.$set(_vm.form_data, "description", $$v)},expression:"form_data.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.notes')}},[_c('b-form-textarea',{attrs:{"disabled":_vm.isEdit,"id":"textarea-default","placeholder":_vm.$t('g.placeholder.enterTheNotesHere'),"rows":"3"},model:{value:(_vm.form_data.notes),callback:function ($$v) {_vm.$set(_vm.form_data, "notes", $$v)},expression:"form_data.notes"}})],1)],1),_c('b-col',{attrs:{"md":_vm.isEdit ? 6 : 12}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.attachments')}},[_c('b-form-file',{ref:"file-input",attrs:{"disabled":_vm.isEdit,"placeholder":_vm.$t('g.chooseAFileOrDropItHere'),"drop-placeholder":_vm.$t('dropFileHere'),"multiple":""},model:{value:(_vm.form_data.attachments),callback:function ($$v) {_vm.$set(_vm.form_data, "attachments", $$v)},expression:"form_data.attachments"}})],1)],1),(_vm.isEdit)?_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex justify-content-start align-items-center h-100"},_vm._l((_vm.form_data.attachments),function(item,index){return _c('a',{key:index,staticClass:"text-primary",attrs:{"href":item.path,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("g.viewDocument"))+" ")])}),0)]):_vm._e()],1)],1),(_vm.isEdit)?_c('b-card',{attrs:{"title":_vm.$t('g.InvestigationDetails')}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.InvestigationResult')}},[_c('validation-provider',{attrs:{"name":"the name of the complainant","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!_vm.is_investigation,"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.entertheInvestigationResultHere')},model:{value:(_vm.investigation_form_data.Investigation_result),callback:function ($$v) {_vm.$set(_vm.investigation_form_data, "Investigation_result", $$v)},expression:"investigation_form_data.Investigation_result"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}],null,false,2583918414)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.InvestigationDate')}},[_c('validation-provider',{attrs:{"name":"complaint date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"disabled":!_vm.is_investigation,"state":errors.length > 0 ? false : null},model:{value:(_vm.investigation_form_data.Investigation_date),callback:function ($$v) {_vm.$set(_vm.investigation_form_data, "Investigation_date", $$v)},expression:"investigation_form_data.Investigation_date"}})]}}],null,false,362916082)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.ComplaintStatus')}},[_c('validation-provider',{attrs:{"name":"complaint status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-select',{attrs:{"disabled":!_vm.is_investigation,"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":_vm.Investigation_options,"text-field":"text","value-field":"value","state":errors.length > 0 ? false : null},model:{value:(_vm.investigation_form_data.is_positive),callback:function ($$v) {_vm.$set(_vm.investigation_form_data, "is_positive", $$v)},expression:"investigation_form_data.is_positive"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}],null,false,1610610178)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.InvestigationNotes')}},[_c('validation-provider',{attrs:{"name":"notes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"disabled":!_vm.is_investigation,"id":"textarea-default","placeholder":_vm.$t('g.placeholder.enterTheNotesHere'),"rows":"3","state":errors.length > 0 ? false : null},model:{value:(_vm.investigation_form_data.Investigation_notes),callback:function ($$v) {_vm.$set(_vm.investigation_form_data, "Investigation_notes", $$v)},expression:"investigation_form_data.Investigation_notes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}],null,false,2013060729)})],1)],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }