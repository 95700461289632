<template>
  <loading v-if="isLoading" />
  <error v-else-if="isError" />
  <content-not-view
    v-else-if="
      !subPermission.show || !subPermission.update || !subPermission.store
    "
  />
  <div v-else>
    <validation-observer ref="complaintForm">
      <b-form>
        <b-card class="sticky-header-control">
          <!-- <div class="pr-0 pl-0 d-flex justify-content-between align-items-center"> -->
          <b-row>
            <b-col class="d-flex align-items-center" md="6">
              <h3 class="text-primary m-0 p-0">
                {{
                  is_investigation
                    ? $t("g.InvestigationDetails")
                    : $t("g.complaint")
                }}
                |
              </h3>
              <b-breadcrumb :items="breadCrumbItems" />
            </b-col>
            <b-col md="6" class="justify-content-end d-flex" v-if="show">
              <b-button
                @click.prevent="validationForm"
                type="submit"
                variant="primary"
                >{{ $t("g.submit") }}</b-button
              >
            </b-col>
          </b-row>
          <!-- </div> -->
        </b-card>
        <b-card :title="$t('g.ComplainantDetails')">
          <b-row>
            <!-- The name of the complainant  -->
            <b-col md="4">
              <b-form-group :label="$t('g.theNameOfTheComplainant')">
                <validation-provider
                  #default="{ errors }"
                  name="the name of the complainant"
                  rules="required"
                >
                  <b-form-input
                    :disabled="isEdit"
                    v-model="form_data.complainant_name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="
                      $t('g.placeholder.entertheNameOfTheComplainantHere')
                    "
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** The name of the complainant  -->
            <!-- The position -->
            <b-col md="4">
              <b-form-group :label="$t('g.position')">
                <validation-provider
                  #default="{ errors }"
                  name="position"
                  rules="required"
                >
                  <b-form-input
                    :disabled="isEdit"
                    v-model="form_data.complainant_job"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="
                      $t('g.placeholder.enterTheComplainantJobHere')
                    "
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** The possition -->
            <!-- Complaint Number  -->
            <b-col md="4">
              <b-form-group :label="$t('g.email')">
                <validation-provider
                  #default="{ errors }"
                  name="Complainant email"
                  rules="required"
                >
                  <b-form-input
                    :disabled="isEdit"
                    v-model="form_data.complainant_email"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="
                      $t('g.placeholder.enterTheComplainantEmailHere')
                    "
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Complaint Number  -->
            <!-- Company name -->
            <b-col md="4">
              <b-form-group :label="$t('g.companyName')">
                <validation-provider
                  #default="{ errors }"
                  name="company name"
                  rules="required"
                >
                  <b-form-input
                    :disabled="isEdit"
                    v-model="form_data.complainant_company"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheCompanyNameHere')"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Company name -->
            <!-- Company address -->
            <b-col md="4">
              <b-form-group :label="$t('g.companyAddress')">
                <validation-provider
                  #default="{ errors }"
                  name="company address"
                  rules="required"
                >
                  <b-form-input
                    :disabled="isEdit"
                    v-model="form_data.complainant_address"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="
                      $t('g.placeholder.enterTheCompanyAddressHere')
                    "
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- ** Company address -->

            <!-- Phone number -->
            <b-col md="4">
              <b-form-group :label="$t('g.phoneNumber')">
                <validation-provider
                  #default="{ errors }"
                  name="phone number"
                  rules="required"
                >
                  <b-form-input
                    :disabled="isEdit"
                    v-model="form_data.complainant_phone"
                    :state="errors.length > 0 ? false : null"
                    type="number"
                    :placeholder="$t('g.placeholder.enterThePhoneNumberHere')"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Phone number -->
          </b-row>
        </b-card>
        <b-card :title="$t('g.ComplaintDetails')">
          <b-row>
            <!-- The Location Of The Complaint Matter -->
            <b-col md="4">
              <b-form-group :label="$t('g.theLocationOfTheComplaintMatter')">
                <validation-provider
                  #default="{ errors }"
                  name="location of the complaint matter"
                  rules="required"
                >
                  <b-form-input
                    :disabled="isEdit"
                    v-model="form_data.place"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="
                      $t(
                        'g.placeholder.enterTheLocationOfTheComplaintMatterHere'
                      )
                    "
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** The Location Of The Complaint Matter -->

            <!-- Complaint Date  -->
            <b-col md="4">
              <b-form-group :label="$t('g.complaintDate')">
                <validation-provider
                  #default="{ errors }"
                  name="complaint date"
                  rules="required"
                >
                  <b-form-datepicker
                    :disabled="isEdit"
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.date"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Complaint Date  -->

            <!-- Complaint summary -->
            <b-col md="12">
              <b-form-group :label="$t('g.complaintSummary')">
                <validation-provider
                  #default="{ errors }"
                  name="complaint summary"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-default"
                    :placeholder="
                      $t('g.placeholder.enterThecomplaintSummaryHere')
                    "
                    :disabled="isEdit"
                    v-model="form_data.description"
                    rows="3"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Complaint summary -->
            <!-- Notes  -->
            <b-col md="12">
              <b-form-group :label="$t('g.notes')">
                <b-form-textarea
                  :disabled="isEdit"
                  id="textarea-default"
                  :placeholder="$t('g.placeholder.enterTheNotesHere')"
                  v-model="form_data.notes"
                  rows="3"
                />
              </b-form-group>
            </b-col>
            <!-- ** Notes  -->
            <!-- Attachments  -->
            <b-col :md="isEdit ? 6 : 12">
              <b-form-group :label="$t('g.attachments')">
                <b-form-file
                  :disabled="isEdit"
                  v-model="form_data.attachments"
                  :placeholder="$t('g.chooseAFileOrDropItHere')"
                  :drop-placeholder="$t('dropFileHere')"
                  ref="file-input"
                  multiple
                />
              </b-form-group>
            </b-col>
            <!-- ** Attachments -->
            <b-col md="6" v-if="isEdit">
              <div
                class="d-flex justify-content-start align-items-center h-100"
              >
                <a
                  v-for="(item, index) in form_data.attachments"
                  :key="index"
                  :href="item.path"
                  class="text-primary"
                  target="_blank"
                >
                  {{ $t("g.viewDocument") }}
                </a>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-card :title="$t('g.InvestigationDetails')" v-if="isEdit">
          <b-row>
            <!-- The name of the complainant  -->
            <b-col md="4">
              <b-form-group :label="$t('g.InvestigationResult')">
                <validation-provider
                  #default="{ errors }"
                  name="the name of the complainant"
                  rules="required"
                >
                  <b-form-input
                    :disabled="!is_investigation"
                    v-model="investigation_form_data.Investigation_result"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="
                      $t('g.placeholder.entertheInvestigationResultHere')
                    "
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** The name of the complainant  -->
            <!-- Complaint Date  -->
            <b-col md="4">
              <b-form-group :label="$t('g.InvestigationDate')">
                <validation-provider
                  #default="{ errors }"
                  name="complaint date"
                  rules="required"
                >
                  <b-form-datepicker
                    :disabled="!is_investigation"
                    :state="errors.length > 0 ? false : null"
                    v-model="investigation_form_data.Investigation_date"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Complaint Date  -->
            <!-- The Complaint status -->
            <b-col md="4">
              <b-form-group :label="$t('g.ComplaintStatus')">
                <validation-provider
                  #default="{ errors }"
                  name="complaint status"
                  rules="required"
                >
                  <b-form-select
                    :disabled="!is_investigation"
                    v-model="investigation_form_data.is_positive"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="Investigation_options"
                    text-field="text"
                    value-field="value"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** The Complaint status -->
            <!-- Notes  -->
            <b-col md="12">
              <b-form-group :label="$t('g.InvestigationNotes')">
                <validation-provider
                  #default="{ errors }"
                  name="notes"
                  rules="required"
                >
                  <b-form-textarea
                    :disabled="!is_investigation"
                    id="textarea-default"
                    :placeholder="$t('g.placeholder.enterTheNotesHere')"
                    v-model="investigation_form_data.Investigation_notes"
                    rows="3"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Notes  -->
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormDatepicker,
  BFormSelect,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BBreadcrumb,
  BImg,
  BModal,
} from "bootstrap-vue";
import { required } from "@validations";
import store from "@/store/index";

import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormDatepicker,
    BFormInput,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormFile,
    BButton,
    BBreadcrumb,
    BImg,
    BModal,
    Loading,
    Error,
    ContentNotView,
  },
  data() {
    return {
      form_data: {
        complainant_name: null,
        complainant_job: null,
        complainant_email: null,
        complainant_phone: null,
        complainant_company: null,
        complainant_address: null,
        place: null,
        date: null,
        description: null,
        notes: null,
        attachments: null,
      },
      investigation_form_data: {
        Investigation_date: null,
        Investigation_result: null,
        Investigation_notes: null,
        is_positive: null,
      },
      show: null,
      is_investigation: null,
      isEdit: null,
      current_id: null,
      Investigation_options: [
        {
          value: null,
          text: this.$t("g.selectComplaintStatus"),
          disabled: true,
        },
        {
          value: 1,
          text: this.$t("g.positive"),
        },
        {
          value: 0,
          text: this.$t("g.malicious"),
        },
      ],
      breadCrumbItems: [
        {
          text: this.$t("g.home"),
          to: { name: "Home" },
        },

        {
          text: this.$t("g.complaint"),
          active: true,
        },
      ],
      isLoading: true,
      isError: false,
      subPermission: {},
    };
  },
  created() {
    this.show = true;
    if (this.$route.params.id) {
      this.current_id = this.$route.params.id;
      this.getComplaintInfo();
      if (this.$route.name === "ShowComplaint") {
        this.isEdit = true;
        this.is_investigation = false;
        this.show = false;
      }
      if (this.$route.name === "InvestigationDetails") {
        this.isEdit = true;
        this.is_investigation = true;
        this.show = true;
      }
    }
  },
  watch: {
    $route(to, from) {
      if (to.name === "Complaint") {
        this.isEdit = false;
        this.is_investigation = false;
        this.clearForm(this.form_data);
      } else if (
        to.name === "ShowComplaint" &&
        from.name === "ListOfComplaint"
      ) {
        this.isEdit = true;
        this.is_investigation = false;
      } else if (
        to.name === "ShowComplaint" &&
        from.name === "AssignedComplaint"
      ) {
        this.isEdit = true;
        this.is_investigation = true;
      }
    },
  },

  beforeMount() {
    if (!this.$route.params.id) {
      setTimeout(() => {
        this.isLoading = false;
        this.isError = false;
        store.dispatch("GET_PERMISSION", "complaints");
        this.subPermission = store.state.permissions.sub;
      }, 2000);
    }
  },

  methods: {
    getComplaintInfo() {
      this.$http
        .get(`admin/complaints/${this.current_id}`)
        .then((res) => {
          for (const key in res.data.data) {
            this.form_data[key] = res.data.data[key];
          }
          for (const item in res.data.data) {
            if (
              item === "Investigation_date" ||
              item === "Investigation_result" ||
              item === "Investigation_notes"
            ) {
              this.investigation_form_data[item] = res.data.data[item];
            }
            if (item === "is_positive") {
              this.investigation_form_data[item] = Number(res.data.data[item]);
            }
          }
          // console.log(this.investigation_form_data);
          setTimeout(() => {
            this.isLoading = false;
            this.isError = false;
            store.dispatch("GET_PERMISSION", "complaints");
            this.subPermission = store.state.permissions.sub;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.isError = true;
        });
    },
    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    clearForm(form_data) {
      for (const i in form_data) {
        form_data[i] = null;
      }
    },
    // Clear input files
    // clearFiles() {
    //   this.$refs["file-input"].reset();
    // },
    validationForm() {
      if (this.form_data.attachments === null) {
        delete this.form_data.attachments;
      }
      this.$refs.complaintForm.validate().then((success) => {
        if (success) {
          this.submitForm();
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },

    submitForm() {
      if (this.isEdit) {
        let formData = new FormData();
        for (let item in this.investigation_form_data) {
          formData.append(item, this.investigation_form_data[item]);
        }
        this.$http
          .post(`admin/complaints/${this.current_id}`, formData, {
            params: { _method: "put" },
          })
          .then((res) => {
            this.makeToast(
              "success",
              this.$t("g.send.successBody"),
              this.$t("g.send.successTitle")
            );
            this.clearForm(this.investigation_form_data);
            this.$refs.complaintForm.reset();
            setTimeout(() => {
              this.$router.push({ name: "ListOfComplaint" });
            }, 500);
          })
          .catch((err) => {
            for (const key in err.response.data.errors) {
              this.makeToast(
                "danger",
                err.response.data.errors[key][0],
                this.$t("g.send.errorTitle")
              );
            }
            console.log(err);
          });
      } else {
        console.log(this.form_data);
        let formData = new FormData();
        for (let item in this.form_data) {
          if (item === "attachments") {
            formData.append(item, this.form_data[item][0]);
          } else {
            formData.append(item, this.form_data[item]);
          }
        }
        this.$http
          .post(`admin/complaints`, formData)
          .then((res) => {
            this.makeToast(
              "success",
              this.$t("g.send.successBody"),
              this.$t("g.send.successTitle")
            );
            this.clearForm(this.form_data);
            this.$refs.complaintForm.reset();
            setTimeout(() => {
              this.$router.push({ name: "ListOfComplaint" });
            }, 500);
          })
          .catch((err) => {
            for (const key in err.response.data.errors) {
              this.makeToast(
                "danger",
                err.response.data.errors[key][0],
                this.$t("g.send.errorTitle")
              );
            }
            console.log(err);
          });
      }
    },
  },
};
</script>

<style>
.showMedia .img-fluid {
  width: 100%;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 650px;
  }
}
</style>
